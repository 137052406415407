<template>
  <div class="price-plan-filter">
    <div class="form-row align-items-center justify-content-between" v-if="type === 'tabs' && device==='desktop'">
      <h4 class="col-md-1 text-left m-0"> {{ $t('search-result.filter')}}:</h4>
      <div class="col-md-4" v-if="!isFcAgentMarketerMode">
        <filter-datepicker
          @dates="chooseDate"
          :availableDates="dateRange"
        ></filter-datepicker>
      </div>
      <div class="col-md-3">
        <filter-night-select
          @changeDestination="changed"
          type="filter-night"
          :options="availNightList"
        />
      </div>
      <div class="col-md-3">
        <filter-night-select
          @changeDestination="changed"
          type="filter-months"
          :options="availMonthsList"
        />
      </div>
    </div>

    <div class="card card-body boxSideSearch border-0" v-if="device==='mobile'">
      <div class="form-row">

        <div class="form-group col-12" v-if="!isFcAgentMarketerMode">
          <div class="input-group">
            <filter-datepicker
              @dates="chooseDate"
              :availableDates="dateRange"
              type="side"
            ></filter-datepicker>
          </div>
        </div>
        <div class="form-group col-12">
          <filter-night-select
            @changeDestination="changed"
            type="filter-night"
            :options="availNightList"
          />
        </div>
        <div class="form-group col-12">
          <filter-night-select
            @changeDestination="changed"
            type="filter-months"
            :options="availMonthsList"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PricePlanFilterPanel',
  components: {
    FilterDatepicker: () => import('@/components/pricePlan/PricePlanFilterDatePicker'),
    FilterNightSelect: () => import('@/components/pricePlan/PricePlanMultipleSelect'),
  },
  props: {
    type: {
      type: String,
      default: 'tabs',
    },
    dateRange: {
      type: Array,
      required: true,
    },
    availNightList: {
      type: Array,
      required: true,
    },
    availMonthsList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fromDate: '',
      toDate: '',
      selectedNights: null,
      selectedMonths: null,
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      marketerId: 'GET_MARKETER_ID',
      device: 'GET_DEVICE',
      isFcAgentMarketerMode: 'GET_FC_AGENT_MARKETER_MODE',
    }),
  },
  methods: {
    chooseDate(date) {
      this.fromDate = date.from;
      this.toDate = date.to;
      this.$emit('sendFilteredData', { from: this.fromDate, to: this.toDate, filterNights: this.selectedNights });
    },
    changed(item) {
      if (!item.selected) return;
      if (item.type === 'filter-night') { this.selectedNights = item.selected.map((itm) => (itm.value)); }
      if (item.type === 'filter-months') { this.selectedMonths = item.selected.map((itm) => (itm.value)); }
      this.$emit('sendFilteredData', { from: this.fromDate, to: this.toDate, filterNights: this.selectedNights, filterMonths: this.selectedMonths });
    },
  },
};
</script>

<style>
.vs__dropdown-toggle {
  background: #e9ecef !important;
}
.price-plan-filter .multiselect__tags {
  border-radius: 0 !important;
}
.price-plan-filter .input-group-append, .price-plan-filter .finish-button {
  display: none;
}
.price-plan-filter .card.boxSideSearch {
  background: transparent;
}
</style>
